<script setup>
import { ref, onMounted, nextTick, computed } from "vue";

import {
  IonIcon,
  IonModal,
  IonList,
  IonItem,
  IonSelect,
  IonSelectOption,
} from "@ionic/vue";

import { ribbon } from "ionicons/icons";

import ApiService from "../../common/service.api";

import { useStore } from "vuex";

import master from "../pk_views/layout/master.vue";

import SectionComponent from "./components/SectionComponent.vue";
import KPICard from "./components/KPICard.vue";
import BadgesCard from "./components/BadgesCard.vue";
import ExpirationCard from "./components/ExpirationCard.vue";

import svg_filter from "./components/svg_filter.vue";
import Button from "./components/Button.vue";

import MasterLayout from "@/components/MasterLayout.vue";

const store = useStore();

const loggedUser = computed(() => store.getters["user/getUserData"]);

const loadingCompetenzeList = ref(false);
const showList1 = ref(false);
const showList2 = ref(false);
const filtro = ref({
  acquisite: null,
  badge: [],
  scadute: null,
});

const competenzeList = ref([]);

const filterButton = ref(null);
const showFilterModal = ref(false);

const competenzeListFiltrate = computed(() => {
  let competenzeFiltrate = [...competenzeList.value];

  // Filtra per stato di acquisizione
  if (filtro.value.acquisite !== null) {
    competenzeFiltrate = competenzeFiltrate.filter((item) => {
      if (filtro.value.acquisite === "acquisite") {
        return item.acquisita;
      } else if (filtro.value.acquisite === "nonacquisite") {
        return !item.acquisita;
      }
      return true;
    });
  }

  // Filtra per tipo di badge
  if (filtro.value.badge.length > 0) {
    competenzeFiltrate = competenzeFiltrate.filter((item) => {
      const badgeTypes = filtro.value.badge;
      return badgeTypes.some((type) => {
        switch (type) {
          case "kpi":
            return (
              item.numBadgeKPIAcquisiti > 0 || item.numBadgeKPINecessari > 0
            );
          case "learning":
            return (
              item.numBadgeLearningAcquisiti > 0 ||
              item.numBadgeLearningNecessari > 0
            );
          case "landing":
            return (
              item.numBadgeLandingAcquisiti > 0 ||
              item.numBadgeLandingNecessari > 0
            );
          case "social":
            return (
              item.numBadgeSocialAcquisiti > 0 ||
              item.numBadgeSocialNecessari > 0
            );
          case "task":
            return (
              item.numBadgeTaskAcquisiti > 0 || item.numBadgeTaskNecessari > 0
            );
          default:
            return false;
        }
      });
    });
  }

  // Filtra per scadenza
  if (filtro.value.scadute !== null) {
    competenzeFiltrate = competenzeFiltrate.filter((item) => {
      const scaduta = daysUntil(item.dataScadenza) <= 0;
      if (filtro.value.scadute === "scadute") {
        return scaduta;
      } else if (filtro.value.scadute === "nonscadute") {
        return !scaduta;
      }
      return true;
    });
  }

  return competenzeFiltrate;
});

function dismiss() {
  this.$refs.modal.$el.dismiss();
}

function resetFiltro() {
  filtro.value = {
    acquisite: null,
    badge: [],
    scadute: null,
  };
}

function calculatePosition() {
  // Logica per calcolare la posizione del filtro
}

function daysUntil(date) {
  //how many days from today to date
  const today = new Date();
  const dateToCheck = new Date(date);
  const diffTime = Math.abs(dateToCheck - today);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
}

async function getCompetenzeStato() {
  competenzeList.value.splice(0);
  try {
    loadingCompetenzeList.value = true;
    let res = await ApiService.get(
      "Competenza/GetCompetenzeStato/" + loggedUser.value.id
    );

    competenzeList.value = res.data;

    setTimeout(async () => {
      await nextTick();
      showList1.value = true;
      loadingCompetenzeList.value = false;
    }, 1);
  } catch (error) {
    console.log(error);
  }
}

onMounted(async () => {
  showList1.value = false;
  showList2.value = false;

  await getCompetenzeStato();

  setTimeout(async () => {
    await nextTick();
    showList2.value = true;
  }, 1);
});
</script>

<template>
  <master-layout :showBack="false" smallTitle="Le tue" bigTitle="Competenze">
    <transition-group
      name="block-slide-in"
      :style="{ '--total': competenzeListFiltrate.length }"
    >
      <template v-for="(c, i) in competenzeListFiltrate" :key="i">
        <div v-if="showList1 && showList2" :style="{ '--i': i }">
          <SectionComponent
            class="my-4"
            :sectionTitle="c.nomeCompetenza"
            sectionItemsName="Badges"
            :sectionPerc="c.completamento + '%'"
            :dataScadenza="c.dataScadenza"
          >
            <template #icon>
              <ion-icon
                style="font-size: 24px"
                :style="
                  !c.acquisita
                    ? { color: 'var(--ion-color-primary)' }
                    : { color: 'lightgray' }
                "
                :icon="ribbon"
              ></ion-icon>
            </template>

            <div class="kpi-grid">
              <KPICard
                v-if="c.numBadgeKPINecessari"
                title="Kpi"
                :value="c.numBadgeKPIAcquisiti || 0"
                :total="c.numBadgeKPINecessari"
              />

              <KPICard
                v-if="c.numBadgeLearningNecessari"
                title="Learning"
                :value="c.numBadgeLearningAcquisiti || 0"
                :total="c.numBadgeLearningNecessari"
              />

              <KPICard
                v-if="c.numBadgeLandingNecessari"
                title="Landing"
                :value="c.numBadgeLandingAcquisiti || 0"
                :total="c.numBadgeLandingNecessari"
              />

              <KPICard
                v-if="c.numBadgeSocialNecessari"
                title="Social"
                :value="c.numBadgeSocialAcquisiti || 0"
                :total="c.numBadgeSocialNecessari"
              />

              <KPICard
                v-if="c.numBadgeTaskNecessari"
                title="Task"
                :value="c.numBadgeTaskAcquisiti || 0"
                :total="c.numBadgeTaskNecessari"
              />
            </div>
            <BadgesCard
              :userBadges="c.userBadges"
              :userBadgesNotCompleted="c.userBadgesNotCompleted"
              class="my-3"
            />
          </SectionComponent>
        </div>

        <div class="separator"></div>
      </template> </transition-group
  ></master-layout>

  <!-- <master>
    <template #header>
      <div class="title-header">
        <span class="mb-2">Le tue</span>
        <span class="font-bold">Competenze</span>
      </div>
      <Button
        id="open-custom-dialog"
        size="small"
        ref="filterButton"
        @click="
          showFilterModal = true;
          calculatePosition();
        "
      >
        Filter
        <template #icon>
          <svg_filter />
        </template>
      </Button>
    </template>

    <div class="separator"></div> -->

  <!-- </master> -->
  <ion-modal id="example-modal" ref="modal" trigger="open-custom-dialog">
    <div class="py-2">
      <ion-list>
        <ion-item>
          <ion-select
            interface="popover"
            placeholder="Acquisizione"
            v-model="filtro.acquisite"
          >
            <IonSelectOption value="acquisite">Acquisite</IonSelectOption>
            <IonSelectOption value="nonacquisite">Non Aquisite</IonSelectOption>
          </ion-select>
        </ion-item>
        <ion-item>
          <ion-select
            interface="popover"
            placeholder="Tipo Badge"
            :multiple="true"
            v-model="filtro.badge"
          >
            <IonSelectOption value="kpi">Kpi</IonSelectOption>
            <IonSelectOption value="learning">Learning</IonSelectOption>
            <IonSelectOption value="landing">Landing</IonSelectOption>
            <IonSelectOption value="social">Social</IonSelectOption>
            <IonSelectOption value="task">Task</IonSelectOption>
          </ion-select>
        </ion-item>
        <ion-item>
          <ion-select
            interface="popover"
            placeholder="Scadenza"
            v-model="filtro.scadute"
          >
            <IonSelectOption value="scadute">Scadute</IonSelectOption>
            <IonSelectOption value="nonscadute">Non Scadute</IonSelectOption>
          </ion-select>
        </ion-item>
      </ion-list>

      <div class="w-full flex justify-content-center align-items-center mt-2">
        <Button @click="resetFiltro()" size="small">Reset filter</Button>
      </div>
    </div>
  </ion-modal>
</template>

<style scoped>
.kpi-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}

.separator {
  height: 1px;
  width: 100%;
  background-color: #e2e9f1;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.title-header {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
}

ion-modal#example-modal {
  --width: fit-content;
  --min-width: 250px;
  --height: fit-content;
  --border-radius: 6px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}

ion-modal#example-modal h1 {
  margin: 20px 20px 10px 20px;
}

ion-modal#example-modal ion-icon {
  margin-right: 6px;

  width: 48px;
  height: 48px;

  padding: 4px 0;

  color: #aaaaaa;
}

ion-modal#example-modal .wrapper {
  margin-bottom: 10px;
}
</style>
