<template>
  <section class="section">
    <!-- Sezione header cliccabile -->
    <div class="section-header" @click="toggleSection">
      <div class="section-title-container">
        <slot name="icon"></slot>
        <h3 class="section-title">{{ sectionTitle }}</h3>
      </div>

      <div
        class="flex flex-column justify-content-end align-items-end text-align-end"
        v-if="dataScadenza"
      >
        <small>Data scadenza: </small
        ><small>{{
          new Date(dataScadenza).toLocaleDateString("it", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          })
        }}</small>
      </div>
    </div>

    <!-- Barra di avanzamento con il numero percentuale sulla stessa riga -->
    <div class="progress-bar-wrapper">
      <div class="progress-bar-container">
        <div class="progress-bar" :style="{ width: sectionPerc }"></div>
      </div>
      <span class="progress-text">{{ sectionPerc }}</span>
    </div>

    <!-- Mostra il contenuto solo se isCollapsed è false -->
    <div v-if="!isCollapsed" class="section-content">
      <h4 class="section-items-name">{{ sectionItemsName }}</h4>
      <slot></slot>
    </div>
  </section>
</template>

<script>
export default {
  name: "SectionComponent",
  props: {
    sectionTitle: String,
    sectionItemsName: String,
    sectionPerc: {
      type: String,
      default: "100%", // Imposta un valore predefinito se non viene passato
    },
    dataScadenza: {
      type: String,
    },
  },

  data() {
    return {
      isCollapsed: false, // Variabile per gestire il collasso
    };
  },
  methods: {
    toggleSection() {
      this.isCollapsed = !this.isCollapsed; // Alterna il valore di isCollapsed
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");

.section {
  background-color: white;
  border-radius: 16px;
  padding: 20px 40px;
  transition: all 0.3s ease;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  cursor: pointer; /* Indica che è cliccabile */
}

.section-title-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  margin-bottom: 12px;
}

.section-icon {
  width: 24px;
  height: 24px;
}

.section-content {
  margin-top: 12px;
  transition: max-height 0.3s ease;
}

.section-title {
  color: #303438;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  margin: 0;
}

.section-items-name {
  color: #656979;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  text-align: start;
}
.progress-bar-wrapper {
  display: flex;
  align-items: center; /* Allinea la barra e il testo al centro verticalmente */
  justify-content: space-between; /* Spazia la barra e il numero a sinistra e destra */
}

.progress-bar-container {
  width: 80%; /* La barra di progresso occupa l'80% della larghezza */
  background-color: var(
    --ion-color-secondary
  ); /* Colore di sfondo della barra */
  height: 5px; /* Altezza fissa */
  border-radius: 5px;
}

.progress-bar {
  height: 100%;
  background-color: var(
    --ion-color-primary
  ); /* Colore della barra di avanzamento */
  border-radius: 5px;
  transition: width 0.3s ease; /* Transizione per la larghezza */
}

.progress-text {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 900;
  color: #303438;
  margin-left: 10px; /* Aggiungi spazio tra la barra e il numero */
  white-space: nowrap; /* Impedisce al numero di andare a capo */
}
</style>
