<template>
  <div class="kpi-card">
    <p class="kpi-title">{{ title }}</p>
    <p class="kpi-value">
      <b>{{ value }}</b> out of {{ total }}
    </p>
  </div>
</template>

<script>
export default {
  name: "KPICard",
  props: {
    title: String,
    value: Number,
    total: Number,
  },
};
</script>

<style scoped>
.kpi-card {
  background-color: var(--ion-color-secondary);
  padding: 16px;
  border-radius: 8px;
  text-align: left;
  width: 100%;
}

.kpi-title {
  font-weight: bold;
  color: #303438;
  margin: 0;
}

.kpi-value {
  color: #656979;
  margin: 0;
}
</style>
